import makeAPIRequest from "../../../services/makeRequest";
import config from "./../../../config";

async function submitApplication(payload, accessToken) {
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/scholarshipapp`;

	const response = await makeAPIRequest(
		API_ENDPOINT,
		"POST",
		payload,
		accessToken
	);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default submitApplication;
