/*eslint-disable*/

import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";

async function sendCpInfo(payload) {
	console.log(payload, "trying");
	const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/communitypartner`;
	const response = await makeAPIRequest(API_ENDPOINT, "POST", payload);
	const jsonResponse = await response.json();
	return jsonResponse;
}

export default sendCpInfo;
