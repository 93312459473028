/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../../../hooks/useUserContext";
import {
	MAGIC_NUMBERS,
	COHATCH_MARKETS,
	COHATCH_LOCATIONS,
	STARTUP_CATEGORY,
} from "../../../../constant";
import { generateClassName, inArray } from "../../../../services/util";
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import ButtonPrimary from "../../../../components/button/ButtonPrimary";
import SelectInput from "../../../../components/select_input/SelectInput";
import notify from "../../../../services/toast";
import { MdLocalSee, MdUpload, MdOutlineAttachFile } from "react-icons/md";
import {
	validateName,
	validateMessage,
	validateEmail,
	validatePhone,
	validateUrl,
} from "../../../../services/validation";
import DefaultState from "../../../../components/default_state/DefaultState";
import { v4 as uuid } from "uuid";
import CauseCard from "../../../../components/cause_card/CauseCard";
import { set, sortBy } from "lodash";
import FileUploader from "../../../../components/file_uploader/FileUploader";
import submitApplication from "../../give_scholarships/submitApplication";
import Modal from "../../../../components/modal/Modal";
import { Navigate } from "react-router-dom";

const libraries = ["places"];

function ScholarshipPrompt() {
	const { userState } = useUserContext();

	// Payload/application information
	const [submitting, setSubmitting] = React.useState(false);
	const [firstName, setFirstName] = React.useState("");
	const [validFirstName, setValidFirstName] = React.useState(false);
	const [lastName, setLastName] = React.useState("");
	const [validLastName, setValidLastName] = React.useState(false);
	const [email, setEmail] = React.useState("");
	const [validEmail, setValidEmail] = React.useState(false);
	const [phoneNumber, setPhoneNumber] = React.useState("");
	const [validPhoneNumber, setValidPhoneNumber] = React.useState(false);
	const [orgName, setOrgName] = React.useState("");
	const [validOrgName, setValidOrgName] = React.useState(false);
	const [orgMission, setOrgMission] = React.useState("");
	const [website, setWebsite] = React.useState("");
	const [validWebsite, setValidWebsite] = React.useState(false);
	const [orgAlign, setOrgAlign] = React.useState("");
	const [orgAmount, setOrgAmount] = React.useState("");
	const [orgImpact, setOrgImpact] = React.useState("");
	const [causes, setCauses] = React.useState([]);
	const [coLocation, setCOLocation] = React.useState("");
	const [idOption, setIdOption] = React.useState("no");
	const [idFile, setIdFile] = React.useState(null);
	const [idReason, setIdReason] = React.useState(null);
	const [orgSponsored, setOrgSponsored] = React.useState("");
	const [googleMap, setGoogleMap] = React.useState(null);
	const [market, setMarket] = React.useState("");
	const [type, setType] = React.useState("");
	const status = "pending";
	const [businessAddress, setBusinessAddress] = useState(null);
	const [dateFounded, setDateFounded] = useState("");
	const [jobRole, setJobRole] = useState("");
	const [category, setCategory] = useState("");
	const [orgOffer, setOrgOffer] = useState("");
	const [orgUses, setOrgUses] = useState("");
	const [logoImage, setLogoImage] = useState();
	const [reapplicant, setReapplicant] = useState();

	const createdAt = new Date();

	const [autoComplete, setAutoComplete] = useState(null);
	//Const location = useLocation();
	const nonprofitId = location.state;
	// End application data
	const [appSuccessModal, setAppSuccessModal] = React.useState(false);
	const [scholarship, setScholarship] = React.useState("prompt");

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
		libraries,
		region: "US",
	});

	// File Upload Information
	const imageCriteria = {
		requiredSize: 6e6,
		requiredDimensions: { width: 1000, height: 800 },
	};
	const imageCriteriaEventOwner = {
		requiredSize: 5e6,
		requiredDimensions: { width: 400, height: 400 },
	};

	function PhotoDisplay({ callback }) {
		return (
			<MdLocalSee
				onClick={callback}
				className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
			/>
		);
	}
	function FileDisplay({ callback }) {
		return (
			<MdOutlineAttachFile
				onClick={callback}
				className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-5xl text-gray-400 cursor-pointer"
			/>
		);
	}

	const scorecardOPTIONS = [
		{ value: 1, label: "1" },
		{ value: 2, label: "2" },
		{ value: 3, label: "3" },
		{ value: 4, label: "4" },
		{ value: 5, label: "5" },
	];

	const scholarOPTIONS = [
		{ value: "no", label: "No, this is my first time applying." },
		{
			value: "yes",
			label: "Yes, I am a scholarship recipient and am re-applying.",
		},
	];
	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			console.log(value, "event");
			setMarket(value);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		} else {
			setMarket(null);
			setCOLocation(null);
			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};

	const handleCategoryChange = (event) => {
		if (event) {
			const { value } = event;

			setCategory(value);
		} else {
			setCategory(null);
		}
	};
	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in COHATCH_MARKETS) {
			cohatchMarket.push(COHATCH_MARKETS[market]);
		}

		return cohatchMarket.flat();
	};

	const handleLocationsChange = (event) => {
		if (event) {
			const { label } = event;

			setCOLocation(label);
		} else {
			setCOLocation(null);

			// setloadLocations(COHATCH_LOCATIONS[value]["locations"]);
		}
	};
	const generateCohatchLocation = () => {
		if (market) {
			const cohatchLocation = [];
			cohatchLocation.push(COHATCH_LOCATIONS[market]["locations"]);
			return cohatchLocation.flat();
		} else {
			return [];
		}
	};
	const onLoad = (autoComplete) => {
		setAutoComplete(autoComplete);
	};
	const onPlaceChanged = () => {
		if (autoComplete !== null) {
			const {
				formatted_address,
				geometry: {
					location: { lat, lng },
				},
			} = autoComplete.getPlace();
			const latitude = lat();
			const longitude = lng();
			setBusinessAddress({
				address: formatted_address,
				coordinates: { lat: latitude, lng: longitude },
				location: { type: "Point", coordinates: [longitude, latitude] },
			});
		} else {
			console.log("Autocomplete is not loaded yet");
		}
	};

	const onPositionChanged = () => {
		if (googleMap) {
			googleMap.panTo(businessAddress.coordinates);
		}
	};

	const generateCauses = (causesArray) => {
		const causes = [];

		for (const cause of causesArray) {
			causes.push({ value: cause, label: cause });
		}

		return causes;
	};

	//Add Causes
	const [userCause, setUserCause] = React.useState(causes || []);
	const [newCause, setNewCause] = React.useState(null);
	const handleAddCause = (event) => {
		event.preventDefault();
		if (!newCause) {
			return;
		}

		if (inArray(userCause, newCause.value)) {
			notify(`${newCause.value} already exists in your causes list.`, "error");
			return;
		}

		setUserCause((prevState) => {
			return [...prevState, newCause.value];
		});
		setCauses((prevState) => {
			return [...prevState, newCause.value];
		});

		setNewCause(null);
	};
	const handleRemoveCause = (event) => {
		const { cause } = event.target.dataset;
		const newCauseList = userCause.filter(
			(value, index) => parseInt(cause, 10) !== index
		);
		setUserCause(newCauseList);
		setCauses(newCauseList);
	};

	const CAUSES = [
		"Animals",
		"Arts, Culture & Humanity",
		"Climate",
		"Community Development",
		"Conservation",
		"Consumption",
		"Crisis",
		"Education",
		"Energy",
		"Equality",
		"Family",
		"Food",
		"Health",
		"Homeless",
		"Jobs",
		"Other",
		"Peace and Justice",
		"Poverty",
		"Refugees",
		"Religious",
		"Water & Sanitation",
		"Minority Owned",
		"Woman Owned",
	];

	const OPTIONS = [
		{ value: "Yes", label: "Yes" },
		{ value: "No", label: "No" },
	];

	function AppType() {
		if (scholarship === "give") {
			setType("give");
		} else if (scholarship === "boost") {
			setType("boost");
		}
	}

	React.useEffect(() => {
		const result = validateName(firstName);
		setValidFirstName(result);
	}, [firstName]);

	React.useEffect(() => {
		const result = validateName(lastName);
		setValidLastName(result);
	}, [lastName]);

	React.useEffect(() => {
		const result = validateEmail(email);
		setValidEmail(result);
	}, [email]);

	React.useEffect(() => {
		const result = validatePhone(phoneNumber);
		setValidPhoneNumber(result);
	}, [phoneNumber]);

	React.useEffect(() => {
		const result = validateMessage(orgName);
		setValidOrgName(result);
	}, [orgName]);

	React.useEffect(() => {
		const result = validateUrl(website);
		setValidWebsite(result);
	}, [website]);

	React.useEffect(() => {
		AppType();
	}, [scholarship]);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		setSubmitting(true);
		try {
			if (!validFirstName) {
				notify("One or more fields are invalid. Check and try again");
			}
			//setCauses(userCause);
			const payload = {
				firstName,
				lastName,
				email,
				website,
				businessAddress,
				phoneNumber,
				orgName,
				orgMission,
				orgAlign,
				orgAmount,
				orgImpact,
				orgSponsored,
				causes,
				type,
				nonprofitId,
				market,
				jobRole,
				category,
				orgOffer,
				orgUses,
				idNumber: {
					idOption: idOption,
					idFile: idFile,
					idReason: idReason,
				},
				appStatus: status,
				cmStatus: status,
				mlStatus: status,
				reapplicant,
				coLocation,
				logoImage,
				createdAt,
			};

			const response = await submitApplication(payload, userState.accessToken);
			if (response.status === "success") {
				setSubmitting(false);
				setFirstName(""), setLastName(""), setEmail("");
				setPhoneNumber("");
				setOrgName("");
				setOrgMission("");
				setWebsite("");
				setBusinessAddress("");
				setCOLocation("");
				setOrgAlign("");
				setOrgAmount("");
				setMarket("");
				setCauses("");
				setOrgImpact("");
				setOrgSponsored("");
				setJobRole("");
				setCategory("");
				setOrgOffer("");
				setOrgUses("");
				setType("");
				setAppSuccessModal(true);
			} else if (response.status === "fail") {
				setSubmitting(false);
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again later. If this error persists please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			setSubmitting(false);
			notify("Something went wrong. Please try again later");
		}
	};

	const handleSubmit = () => {
		setAppSuccessModal(false);
		//setCurrentStep(currentStep + 1);
	}
	console.log(firstName, causes, userCause);
	return (
		<>

			<div className="w-full  overflow-hidden shadow rounded-lg">
				<div className="bg-gray-50 w-full text-center px-4 py-5 sm:p-6 space-y-2">
					<div>
						{/* <h1 className="text-3xl font-bold text-success-800">Would you like to apply for the Impact Scholarship?</h1> */}
						<p className="">Are you a nonprofit or startup looking for a space to drive your mission forward? Apply for our scholarship program to receive discounted access to our locations to help you grow, innovate, and make our communities greater.</p>
						<p className="font-bold pt-2">*If you are currently a COhatch member, you are not eligible to apply for scholarship.</p>
					</div>
					<div className="mb-32">
						<div className="flex flex-row ">
							<div className="w-full h-auto m-2">
								<div
									className="max-w-6xl block rounded-lg bg-success-150">
									<img
										className="rounded-t-lg h-full w-full"
										src="https://www.cohatch.com/wp-content/uploads/2024/01/03.21.22_Dolr-20_web-1200x800.jpg"
										alt="" />
									<div className="p-6">
										<h5
											className="mb-2 text-xl font-bold leading-tight text-success-800">
											Apply for our BOOST Scholarship
										</h5>
										{/* <p className="mb-4 text-base text-success-800">
											Work alongside other innovative startups at COhatch through our scholarship program.
										</p> */}
										<a
											href="/boost_application"
											target="_blank"
											className="w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-success-800 hover:bg-success-50 focus:outline-none focus:ring-2 focus:ring-offset-2  transition ease-linear duration-500" size="md" >Startups Apply Here</a>
									</div>
								</div>
							</div>
							<div className="w-full h-auto m-2">
								<div
									className="max-w-6xl block rounded-lg bg-success-150">
									<img
										className="rounded-t-lg h-full w-full"
										src="https://www.cohatch.com/wp-content/uploads/2024/01/Worthington-Hardware_Community-Hall-1_web-1200x800.jpg"
										alt="" />
									<div className="p-6">
										<h5
											className="mb-2 text-xl font-bold leading-tight text-success-800">
											Apply for our GIVE Scholarship
										</h5>
										{/* <p className="mb-4 text-base text-success-800">
											Work alongside other nonprofits working to make our communities greater through our scholarship program.
										</p> */}
										<a
											href="/give_application"
											target="_blank"
											className="w-full inline-flex items-center justify-center gap-2 px-2.5 py-1.5 border border-transparent text-md font-medium rounded shadow-sm text-white bg-success-800 hover:bg-success-50 focus:outline-none focus:ring-2 focus:ring-offset-2transition ease-linear duration-500" size="md" >Nonprofits Apply Here</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-12">
					</div>
				</div>

			</div>
		</>
	);
}

export default ScholarshipPrompt;
