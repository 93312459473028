/* eslint-disable */
import React, { useState } from "react";
import useUserContext from "../../hooks/useUserContext";
import { MAGIC_NUMBERS, APP_MARKETS } from "../../constant";
import notify from "../../services/toast";
import submitCPInfo from "./submitCPInfo";
import Logo from "../../components/logo/Logo";
import SelectInput from "../../components/select_input/SelectInput";


function CommunityPartnerForm() {
	const { userState } = useUserContext();
	const [orgName, setOrgName] = useState();
	const [firstName, setFirstName] = useState();
	const [lastName, setLastName] = useState();
	const [emailAddress, setEmailAddress] = useState();
	const [cohatchUses, setCohatchUses] = useState();
	const [phoneNumber, setPhoneNumber] = useState();
	const [market, setMarket] = React.useState("");
	const [website, setWebsite] = React.useState("");
	const [orgMission, setOrgMission] = React.useState("");

	console.log(lastName);

	const generateCohatchMarket = () => {
		const cohatchMarket = [];

		for (const market in APP_MARKETS) {
			cohatchMarket.push(APP_MARKETS[market]);
		}

		return cohatchMarket.flat();
	};

	const handleMarketChange = (event) => {
		if (event) {
			const { value } = event;
			console.log(value, "event");
			setMarket(value);
		} else {
			setMarket(null);
		}
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		try {
			const payload = {
				orgName,
				firstName,
				lastName,
				phoneNumber,
				cohatchUses,
				emailAddress,
				market,
				website,
				orgMission,
			};
			const response = await submitCPInfo(payload);

			if (response.status === "success") {
				console.log("success");
			} else if (response.status === "fail") {
				let displayMessage = "";
				if (Array.isArray(response.message)) {
					displayMessage = response.message[0].message;
				} else {
					displayMessage = response.message;
					if (MAGIC_NUMBERS.SERVER_ERROR_CODES.includes(response.statusCode)) {
						displayMessage =
							"An error has occurred. Please try again larer. If this error persists, please contact support";
					}
				}
				notify(displayMessage, "error");
			}
		} catch (error) {
			notify("Unable to submit form. Please try again later");
		}
	};

	// const communityPartnerCategories = [
	// 	{ value: "local_city_leaders", label: "Local City Leaders" },
	// 	{ value: "chamber_of_commerce", label: "Chamber of Commerce" },
	// 	{ value: "university_partner", label: "University Partner" },
	// 	{ value: "local_influencer", label: "Local Influencer" },
	// 	{ value: "networking_groups", label: "Networking Groups" },
	// 	{ value: "events_and_advertising", label: "Events & Advertising" },
	// 	{ value: "main_street_business", label: "" },
	// ];

	return (
		<div className="">
			<header className="bg-primary-400">
				<nav className="mx-auto max-w-7xl px-6 lg:px-8" aria-label="Top">
					<div className="flex w-full items-center justify-center py-6">
						<Logo color="white" />
					</div>
				</nav>
			</header>


			<div className="flex flex-col items-center justify-center min-h-screen bg-[url('/src/assets/grungeTextureLight.png')] bg-no-repeat bg-cover py-8 px-6">
				<div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-2xl">
					<h1 className="text-3xl font-bebas text-success-800 font-bold mb-4 text-center">
						Community Partner Invite
					</h1>
					<p className="text-lg leading-relaxed mb-4 text-center">
						You've been invited to join COhatch as a Community Partner! Please enter and submit your information so a Market Leader can review and get you started on next steps.
					</p>
					<h2 className="text-xl font-bold mb-2 text-center">
						What is a Community Partner?
					</h2>
					<p className="text-lg leading-relaxed mb-6 text-center">
						A Community Partner is an organization COhatch chooses to be a part of our community. Their missions align with COhatch's mission. Being a Partner will give you access to one of our locations at a discounted rate to use our co-working spaces and meeting rooms.
					</p>
					<form
						onSubmit={onSubmit}
						noValidate
						autoComplete="off"
						className="space-y-6"
					>
						<div>
							<label className="block font-bold mb-2" htmlFor="orgName">
								Organization Name
							</label>
							<input
								className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
								name="orgName"
								type="text"
								id="orgName"
								placeholder="Organization Name"
								onChange={(e) => setOrgName(e.target.value)}
							/>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="firstName">
									Point of Contact First Name
								</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
									name="firstName"
									type="text"
									id="firstName"
									placeholder="First Name"
									onChange={(e) => setFirstName(e.target.value)}
								/>
							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="lastName">
									Point of Contact Last Name
								</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
									name="lastName"
									type="text"
									id="lastName"
									placeholder="Last Name"
									onChange={(e) => setLastName(e.target.value)}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="emailAddress">
									Email Address
								</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
									name="emailAddress"
									type="email"
									id="emailAddress"
									placeholder="Email Address"
									onChange={(e) => setEmailAddress(e.target.value)}
								/>
							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="phoneNumber">
									Phone Number
								</label>
								<input
									className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
									name="phoneNumber"
									type="tel"
									id="phoneNumber"
									placeholder="Phone Number"
									onChange={(e) => setPhoneNumber(e.target.value)}
								/>
							</div>
						</div>
						<div className="flex flex-col gap-6 md:flex-row md:gap-6">
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="phoneNumber">
									Which COhatch Market are you interested in?
								</label>

								<SelectInput
									label="COhatch City"
									name="market"
									id="market"
									options={generateCohatchMarket()}
									noOptionsMessage="No cities found"
									placeholder="Select a COhatch City"
									setSelectedValue={handleMarketChange}
									validation={{
										required: {
											value: true,
											message: "required",
										},
									}}
									onChange={(e) => setMarket(e.target.value)}
								/>

							</div>
							<div className="flex-1">
								<label className="block font-bold mb-2" htmlFor="phoneNumber">
									Website:
								</label>
								<input
									type="string"
									name="website"
									id="website"
									className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
									label="Organization Website"
									placeholder="Enter website"
									validation={{
										required: {
											value: false,
										},
										pattern: {
											value:
												/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,255}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
											message: "not a valid website",
										},
									}}
									onChange={(e) => setWebsite(e.target.value)}
								/>
							</div>
						</div>
						<div>
							<label className="block font-bold mb-2" htmlFor="cohatchUses">
								Description of your organization and mission?
							</label>
							<input
								multiline={true}
								rows="3"
								className=" appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 sm:text-md"
								type="textarea"
								name="orgMission"
								id="orgMission"
								placeholder="description here..."
								validation={{
									required: {
										value: true,
										message: "required",
									},
								}}
								onChange={(e) => setOrgMission(e.target.value)}
							/>
						</div>
						<div>
							<label className="block font-bold mb-2" htmlFor="cohatchUses">
								How would you like to utilize COhatch?
							</label>
							<textarea
								className="block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-400 focus:border-primary-400 text-sm"
								name="cohatchUses"
								id="cohatchUses"
								placeholder="Enter Response"
								onChange={(e) => setCohatchUses(e.target.value)}
							></textarea>
						</div>
						<div className="flex justify-center mt-4">
							<button
								className="px-5 py-2 border border-transparent text-md font-medium rounded-lg shadow-sm text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-400 transition ease-linear duration-300"
								type="submit"
							>
								Submit
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default CommunityPartnerForm;
