/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from "react";
import Logo from "../../components/logo/Logo";
import { useSearchParams } from "react-router-dom";
import Alert from "../../components/alert/Alert";
import makeAPIRequest from "../../services/makeRequest";
import config from "../../config";
import Spinner from "../../components/spinner/Spinner";
import useScrollPage from "../../hooks/useScrollPage";

function CpThankYou() {
  useScrollPage();
  const [queryParams, setQueryParams] = useSearchParams();
  const token = queryParams.get("token");

  const [verifying, setVerifying] = React.useState(false);
  const [error, setError] = React.useState(true);

  const calledOnce = React.useRef(false);

  React.useEffect(() => {
    const verifyingAcceptance = async () => {
      try {
        setVerifying(true);
        const API_ENDPOINT = `${config["API_BASE_ENDPOINT"]}/scholarship/accept-terms?token=${token}`;
        const response = await makeAPIRequest(API_ENDPOINT, "GET");
        if (response.ok) {
          setQueryParams("");
          setError(false);
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setVerifying(false);
        calledOnce.current = true;
      }
    };

    if (calledOnce.current) {
      return;
    }
    if (token) {
      verifyingAcceptance();
    } else {
      setVerifying(false);
      setError(false);
    }
  }, [token, setQueryParams]);

  return (
    <div className="h-screen w-full flex flex-col justify-center md:items-center py-12 sm:px-6 lg:px-8 font-roboto bg-[url('https://cohatch-media-assets.nyc3.digitaloceanspaces.com/static/images/background-image.png')] bg-no-repeat bg-cover">
      <div className="flex justify-center">
        <Logo color="green" />
      </div>
      <div className="px-4 sm:mx-auto sm:w-full sm:max-w-md space-y-5">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-primary-400">Terms and Conditions Acceptance</h2>
        {!verifying && !error ? (
          <Alert
            color="primary"
            heading="Thank you for accepting the terms!"
            body={[
              "You have successfully accepted the terms and conditions of the scholarship.",
              "Your application has been signed.",
            ]}
            direction="/dashboard"
          />
        ) : error ? (
          <Alert
            color="red"
            heading="We could not verify your acceptance."
            body={[
              "We were unable to verify your acceptance of the terms.",
              "If you have already accepted, please go to the dashboard.",
              "If you have any issues, contact support@cohatchplus.com.",
            ]}
            direction="/dashboard"
          />
        ) : (
          <Spinner displayText="Verifying acceptance, please wait..." />
        )}
      </div>
      <div className="mt-10 p-5 text-xs text-gray-500 text-center">&copy; COhatch/COhatch+ All rights reserved</div>
    </div>
  );
}

export default CpThankYou;
